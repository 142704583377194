$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.caption {
  cursor: pointer;
  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &_title {
      display: flex;
      align-items: center;
      gap: 24px;
      color: $black;

      &_circle {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Inter', serif;
        font-size: 14px;
        font-weight: 400;
        min-height: 36px;

        &_active {
          background: #17B67C;
          color: white;
        }

        &_disabled {
          border: 1px #BABCBE solid;
          background: #EDEDED;
          color: #96A1A9;
        }
      }

      label {
        cursor: pointer;
        font-size: 20px;
        font-family: Inter, serif;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word
      }
    }

    &_arrow {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      transition: transform 0.5s ease-in;
      transform-style: flat;
      margin-right: 10px;


      &__opened {
        transform: rotate(180deg);
      }
    }
  }
}

.content {
  will-change: max-height;
  overflow: hidden;
  max-height: 0;

  &_show {
    min-height: max-content;
    overflow: unset;
  }

  &_hide {
    max-height: 0;
    overflow: hidden;
  }
}



