$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.fields {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: minmax(245px, 30%) 86px auto 110px;
  //grid-template-columns: 11fr 10fr 3fr;
  align-items: start;



  &_verticalCenter {
    align-items: center;
  }
}

.row {
  display: grid;
  grid-row-gap: 24px;
}

.selectButton {
  margin-top: 27px;
}



@-webkit-keyframes f {
  0% {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes f {
  0% {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progressbar {
  height: 44px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: f 2s linear infinite;
  animation: f 2s linear infinite;
  background-image: linear-gradient(
                  45deg,
                  hsla(0, 0%, 95%, 0.8) 25%,
                  transparent 0,
                  transparent 50%,
                  hsla(0, 0%, 95%, 0.8) 0,
                  hsla(0, 0%, 95%, 0.8) 75%,
                  transparent 0,
                  transparent
  ) !important;
  background-size: 40px 40px !important;


}




